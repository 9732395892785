import Vue from "vue";
import Vuex from "vuex";
import AgentModule from "./agent";
Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";
// const ls = new SecureLS({ encodingType: "aes" });

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    agent: AgentModule,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
    // createPersistedState({
    //   storage: {
    //     getItem: (key) => ls.get(key),
    //     setItem: (key, value) => ls.set(key, value),
    //     removeItem: (key) => ls.remove(key)
    //   }
    // })
  ],
});
