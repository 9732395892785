import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import axios from "axios";
import "./filters/formatCurrency";
import "./plugins/vuetify-money.js";
import "./plugins/moment.js";

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

// Console log CarTrust console
const cssRule =
  "color: rgb(249, 162, 34);" +
  "font-size: 60px;" +
  "font-weight: bold;" +
  "text-shadow: 1px 1px 5px rgb(249, 162, 34);" +
  "filter: dropshadow(color=rgb(249, 162, 34), offx=1, offy=1);";

console.warn("%cCarTrust", cssRule);

console.log("Base url", axios.defaults.baseURL);
// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const TOKEN = store.getters["agent/getAgentToken"];

    console.log("store", store);
    if (TOKEN) config.headers.Authorization = "Token " + TOKEN;

    console.log("Request config: ", config);

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  (error) => {
    /*
      Any status codes that falls outside the range of 2xx cause this function to trigger
      Do something with response error
    */
    console.log("INSTANCE err:", error);
    // Return any error which is not due to authentication back to the calling service
    if (error.response) {
      console.log("Error response: ", error.response);
      console.log("Error response status: ", error.response.status);

      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      } else {
        console.log("401:", error);
        console.log("error.config", error.config);
        console.log("error.response", error.response);
        router.push("/");
      }
    }

    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: (h) => h(App),
}).$mount("#app");
