// import menuUI from './menus/ui.menu'
import menuApps from './menus/apps.menu'
// import menuPages from './menus/pages.menu'

const internal = {
  // main navigation - side menu
  menu: [
    // {
    //   text: '',
    //   key: '',
    //   items: [
    //     {
    //       icon: 'mdi-view-dashboard-outline',
    //       key: 'menu.dashboard',
    //       text: 'Dashboard',
    //       link: '/dashboard/analytics'
    //     }
    //   ]
    // },
    {
      text: 'Apps',
      items: menuApps,
      adminonly: true
    },
    // Remove Unrelated end points
    // {
    //   text: 'Landing Pages',
    //   items: [
    //     { icon: 'mdi-airplane-landing', key: 'menu.landingPage', text: 'Landing Page', link: '/landing' },
    //     { icon: 'mdi-cash-usd-outline', key: 'menu.pricingPage', text: 'Pricing Page', link: '/landing/pricing' }
    //   ]
    // },
    {
      text: 'CarTrust',
      items: [
        //  NOTE: COMMENT LEAD
        {
          icon: 'mdi-car',
          text: 'Car Lead Management',
          regex: /^\/lead/,
          items: [
            { text: 'Dashboard', link: '/lead/dashboard/car' },
            { text: 'Summary', link: '/lead/summary/car' },
            // { text: 'Add New Lead', link: '/lead/add' },
            {
              text: 'Untouched Leads',
              link: '/lead/untouched/car'
            },
            {
              text: 'Waiting Leads',
              link: '/lead/wating/car'
            },
            {
              text: 'Followed Leads',
              link: '/lead/followed/car'
            },
            { text: 'Dropped Leads', link: '/lead/drop/car' },
            { text: 'Qualified Leads', link: '/lead/qualified/car' },
            { text: 'Qualified To Kauto', link: '/lead/qualifiedToKauto/car' },
            { text: 'Approved Leads', link: '/lead/approved/car' },
            // { text: 'Disqualified Leads', link: '/lead/disqualified' },
            { text: 'All Leads', link: '/lead/all/car' }
          ]
        },
        {
          icon: 'mdi-home-city',
          text: 'Home Lead Management',
          regex: /^\/lead/,
          items: [
            { text: 'Dashboard', link: '/lead/dashboard/home' },
            { text: 'Summary', link: '/lead/summary/home' },
            {
              text: 'Untouched Leads',
              link: '/lead/untouched/home'
            },
            {
              text: 'Waiting Leads',
              link: '/lead/wating/home'
            },
            {
              text: 'Followed Leads',
              link: '/lead/followed/home'
            },
            { text: 'Dropped Leads', link: '/lead/drop/home' },
            { text: 'Qualified Leads', link: '/lead/qualified/home' },
            { text: 'Approved Leads', link: '/lead/approved/home' },
            { text: 'All Leads', link: '/lead/all/home' }
          ]
        },
        {
          icon: 'mdi-phone-incoming',
          text: 'Call Lead Management',
          regex: /^\/lead/,
          items: [
            { text: 'Dashboard', link: '/lead/dashboard/call' },
            { text: 'Summary', link: '/lead/summary/call' },
            {
              text: 'Untouched Leads',
              link: '/lead/untouched/call'
            },
            {
              text: 'Waiting Leads',
              link: '/lead/wating/call'
            },
            {
              text: 'Followed Leads',
              link: '/lead/followed/call'
            },
            { text: 'Dropped Leads', link: '/lead/drop/call' },
            { text: 'Qualified Leads', link: '/lead/qualified/call' },
            { text: 'Approved Leads', link: '/lead/approved/call' },
            { text: 'All Leads', link: '/lead/all/call' }
          ]
        },
        {
          icon: 'mdi-file-outline',
          text: 'Book Closing',
          items: [
            {
              text: 'Dashboard',
              link: '/dashboard/analytics'
            },
            {
              text: 'Summary',
              link: '/cases/summary'
            },
            {
              text: 'Incoming case (CT)',
              link: '/cases/incomingGB'
            },
            {
              text: 'AR follow up',
              link: '/cases/v2/all/all/true/false'
            },
            {
              text: 'Bad Dept Case',
              link: '/cases/v2/all/all/true/true'
            },
            {
              text: 'Incoming case from Sale (CT)',
              link: '/cases/incoming'
            },
            {
              text: 'Untouched case',
              link: '/cases/v2/car/Untouched/false/false'
            },
            {
              text: 'Follow up active case',
              link: '/cases/v2/car/Followed/false/false'
            },
            {
              text: 'Car loan case',
              link: '/cases/v2/car/all/false/false'
            },
            {
              text: 'Home loan case',
              link: '/cases/v2/home/all/false/false'
            },
            {
              text: 'Personal loan/credit case',
              link: '/cases/v2/personal/all/false/false'
            },
            {
              text: 'Export Excel',
              link: '/export_excel'
            }
          ]
        },
        // {
        //   icon: 'mdi-application-export',
        //   text: 'Export Excel',
        //   link: '/export_excel'
        // },
        {
          icon: '฿',
          text: 'Accounting',
          regex: /^\/finance/,
          items: [
            { text: 'Summary', link: '/accounting_summary' },
            { text: 'Daily ปิดเล่ม', link: '/accounting' },
            { text: 'Financing / สรุปรายละเอียดยอดโอน', link: '/finance' },
            { text: 'Export Excel', link: '/accounting_export' }
          ]
        },
        // {
        //   icon: 'mdi-file-table',
        //   text: 'Summary Report',
        //   link: '/summary'
        // },
        // {
        //   icon: 'mdi-file-table',
        //   text: 'Coupon',
        //   link: '/coupon'
        // },
        {
          icon: 'mdi-file-table',
          text: 'Coupon',
          items: [
            {
              text: 'Create Coupon',
              link: '/coupon'
            },
            {
              text: 'Apply Coupon',
              link: '/apply_coupon'
            }
          ]
        }
      ]
    },
    {
      text: 'Public',
      items: [
        {
          icon: 'mdi-account',
          text: 'Customer',
          link: '/customer'
        }
      ]
    },
    {
      text: 'Configurations',
      items: [
        {
          icon: 'mdi-cog-outline',
          text: 'Settings',
          link: '/settings'
        }
      ]
    }
  ]
}

const external = {
  // main navigation - side menu
  menu: [
    {
      text: '',
      key: '',
      items: [
        {
          icon: 'mdi-view-dashboard-outline',
          key: 'menu.dashboard',
          text: 'Dashboard',
          link: '/dashboard/analytics'
        }
      ]
    },
    {
      text: 'External',
      items: [
        {
          icon: 'mdi-file-outline',
          text: 'Report',
          link: '/report'
        }
      ]
    }
  ]
}

const agent = {
  // main navigation - side menu
  menu: [
    {
      text: '',
      key: '',
      items: [
        {
          icon: 'mdi-view-dashboard-outline',
          key: 'menu.dashboard',
          text: 'Dashboard',
          link: '/dashboard/analytics'
        }
      ]
    },
    {
      text: 'Agent',
      items: [
        {
          icon: 'mdi-file-outline',
          text: 'Home Untouched',
          link: '/lead/untouched/home'
        }
      ]
    }
  ]
}

export default { internal, external, agent }
