export default {
  getAgent: (state) => {
    return state.loggedInAgent;
  },
  getAgentToken: (state) => {
    return state.loggedInAgent ? state.loggedInAgent.token : "";
  },
  getAgentInfo: (state) => {
    return state.loggedInAgentInfo;
  },
  getValidBrands: (state) => {
    return state.brands;
  },
  getModels: (state) => {
    return state.models;
  },
  getValidYears: (state) => {
    return state.years;
  },
  getValidSubModelList: (state) => {
    return state.subModelList;
  },
  getPrice: (state) => {
    return state.price;
  },
  getPrice150: (state) => {
    return state.price_150_percent;
  },
  getNewLead: (state) => {
    return state.new_lead;
  },
  getAllUsers: (state) => {
    return state.allUsers
  }
};
