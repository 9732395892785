export default {
  loggedInAgent: null,
  loggedInAgentInfo: null,
  brands: [],
  models: [],
  years: [],
  subModelList: [],
  price: null || "โปรดเลือกรุ่นรถของคุณ",
  price_150_percent: null || "โปรดเลือกรุ่นรถของคุณ",
  new_lead: null,
  allUsers: []
};
