export default {
  common: {
    add: 'เพิ่ม', //'Add',
    cancel: 'ยกเลิก', //'Cancel',
    description: 'รายละเอียด', //'Description',
    delete: 'ลบข้อมูล', //'Delete',
    title: 'หัวข้อ', //'Title',
    save: 'บันทึกข้อมูล', //'Save',
    faq: 'FAQ', //'FAQ',
    contact: 'ติดต่อเรา', //'Contact Us',
    tos: 'เงื่อนไขและข้อตกลงการใช้บริการ', //'Terms of Service',
    policy: 'นโยบาย' //'Privacy Policy'
  },
  board: {
    titlePlaceholder: 'ใส่หัวข้อ', //'Enter a title for this card',
    deleteDescription: 'ใส่รายละเอียด', //'Are you sure you want to delete this card?',
    editCard: 'แก้ไขการ์ด', //'Edit Card',
    deleteCard: 'ลบการ์ด', //'Delete Card',
    state: {
      TODO: 'ที่ต้องทำ', //'TODO',
      INPROGRESS: 'ดำเนินการ', //'INPROGRESS',
      TESTING: 'ทดสอบ', //'TESTING',
      DONE: 'เสร็จสิ้น' //'DONE'
    }
  },
  chat: {
    online: 'ออนไลน์', //'Users Online ({count})',
    addChannel: 'เพิ่มแชนเนล', //'Add Channel',
    channel: 'แชนเนล', //'Channel | Channels',
    message: 'ข้อความ' //'Message'
  },
  email: {
    compose: 'อีเมล', //'Compose Email',
    send: 'ส่งข้อความ', //'Send',
    subject: 'หัวข้อ', //'Subject',
    labels: 'Labels', //'Labels',
    emptyList: 'ไม่มีอีเมล', //'Empty email list',
    inbox: 'อินบอกซ์', //'Inbox',
    sent: 'ส่งแล้ว', //'Sent',
    drafts: 'ดราฟท์', //'Drafts',
    starred: 'สำคัญ', //'Starred',
    trash: 'ขยะ', //'Trash',
    work: 'ทำงาน', //'Work',
    invoice: 'อินวอยซ์' //'Invoice'
  },
  todo: {
    addTask: 'เพิ่มงาน', //'Add Task',
    tasks: 'งาน', //'Tasks',
    completed: 'เสร็จสิ้น', //'Completed',
    labels: 'Labels' //'Labels'
  },
  dashboard: {
    activity: 'การเคลื่อนไหวล่าสุด', //'Activity',
    weeklySales: 'Sales รายสัปดาห์', //'Weekly Sales',
    sales: 'Sales', //'Sales',
    recentOrders: 'รายการสั่งซื้อล่าสุด', //'Recent Orders',
    sources: 'Traffic Sources', //'Traffic Sources',
    lastweek: 'สัปดาห์ก่อนหน้า', //'vs last week',
    orders: 'รายการ', //'Orders',
    customers: 'ลูกค้า', //'Customers',
    tickets: 'Support Tickets', //'Support Tickets',
    viewReport: 'ดูรีพอร์ท' //'View Report'
  },
  usermenu: {
    profile: 'โปรไฟล์', //'Profile',
    signin: 'ลงชื่อเข้าใช้', //'Sign In',
    dashboard: 'แดชบอร์ด', //'Dashboard',
    signout: 'ออกจากการใช้งาน' //'Sign Out'
  },
  error: {
    notfound: 'ไม่พบหน้าที่ต้องการ', //'Page Not Found',
    other: 'เกิดข้อผิดพลาด' //'An Error Ocurred'
  },
  check: {
    title: 'ตั้งรหัสผ่านใหม่', //'Set New Password',
    backtosign: 'กลับไปหน้าลงชื่อเข้าใช้งาน', //'Back to Sign In',
    newpassword: 'รหัสผ่านใหม่', //'New Password',
    button: 'ตั้งรหัสผ่านใหม่ และ ลงชื่อเข้าใช้งาน', //'Set new password and Sign in',
    error: 'ไม่สามารถใช้งาน', //'The action link is invalid',
    verifylink: 'ยืนยันลิ้ง', //'Verifying link...',
    verifyemail: 'ยืนยันอีเมล', //'Verifying email address...',
    emailverified: 'ยืนยันอีเมลสำเร็จ' //'Email verified! Redirecting...'
  },
  forgot: {
    title: 'ลืมรหัสผ่าน', //'Forgot Password?',
    subtitle: 'ยืนยันอีเมลแล้วเราจะลิ้งรีเซตรหัสผ่านให้กับคุณ', //'Enter your account email address and we will send you a link to reset your password.',
    email: 'อีเมล', //'Email',
    button: 'ลืมรหัสผ่าน', //'Request Password Reset',
    backtosign: 'กลับหน้าหลัก' //'Back to Sign In'
  },
  login: {
    title: 'ลงชื่อเข้าใช้งาน', //'Sign In',
    email: 'อีเมล', //'Email',
    password: 'รหัสผ่าน', //'Password',
    button: 'ลงชื่อเข้าใช้งาน', //'Sign In',
    orsign: 'หรือ ล็อกอินด้วย', //'Or sign in with',
    forgot: 'ลืมรหัสผ่าน', //'Forgot password?',
    noaccount: 'ยังไม่มีบัญชี?', //'Don\'t have an account?',
    create: 'สร้างบัญชี', //'Create one here',
    error: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง' //'The email / password combination is invalid'
  },
  register: {
    title: 'สร้างบัญชีใหม่', //'Create Account',
    name: 'ชื่อ นามสกุล', //'Full name',
    email: 'อีเมล', //'Email',
    password: 'รหัสผ่าน', //'Password',
    button: 'สร้างบัญชี', //'Create Account',
    orsign: 'หรือ ล็อกอินด้วย', //'Or sign up with',
    agree: 'ยินยอม', //'By signing up, you agree to the',
    account: 'มีบัญชีแล้ว?', //'Already have an account?',
    signin: 'ลงชื่อเข้าใช้งาน' //'Sign In'
  },
  utility: {
    maintenance: 'กำลังปรับปรุง' //'In Maintenance'
  },
  faq: {
    call: 'สงสัย ติดต่อสอบถาม' //'Have other questions? Please reach out '
  },
  menu: {
    search: 'ค้นหา กด \'ctrl + /\' to focus', //'Search (press \'ctrl + /\' to focus)',
    dashboard: 'แดชบอร์ด', //'Dashboard',
    logout: 'ออกจากระบบ', //'Logout',
    profile: 'โปรไฟล์ผู้ใช้งาน', //'Profile',
    blank: 'หน้าเปล่า', //'Blank Page',
    pages: 'หน้า', //'Pages',
    others: 'อื่นๆ', //'Others',
    email: 'อีเมล', //'Email',
    chat: 'แชท', //'Chat',
    todo: 'Todo', //'Todo',
    board: 'บอร์ดงาน', //'Task Board',
    users: 'กลุ่มผู้ใช้', //'Users',
    usersList: 'รายชื่อผู้ใช้', //'List',
    usersEdit: 'แก้ไขผู้ใช้งาน', //'Edit Logged In User',
    auth: 'หน้ายืนยัน', //'Auth Pages',
    authLogin: 'ลงชื่อเข้าใช้งาน', //'Signin / Login',
    authRegister: 'ลงทะเบียน', //'Signup / Register',
    authVerify: 'ยืนยันอีเมล', //'Verify Email',
    authForgot: 'ลืมรหัสผ่าน', //'Forgot Password',
    authReset: 'ขอรหัสผ่านใหม่', //'Reset Password',
    errorPages: 'Error Pages', //'Error Pages',
    errorNotFound: 'ไม่พบหน้าที่ต้องการ', //'Not Found / 404',
    errorUnexpected: '', //'Unexpected / 500',
    utilityPages: '', //'Utility Pages',
    utilityMaintenance: '', //'Maintenance',
    utilitySoon: '', //'Coming Soon',
    utilityHelp: '', //'FAQs / Help',
    levels: '', //'Menu Levels',
    disabled: '', //'Menu Disabled',
    docs: '', //'Documentation',
    feedback: '', //'Feedback',
    support: '' //'Support'
  },
  // Vuetify components translations
  $vuetify: {
    badge: '', //'Badge',
    close: '', //'Close',
    dataIterator: {
      noResultsText: '', //'No matching records found',
      loadingText: '' //'Loading items...'
    },
    dataTable: {
      itemsPerPageText: '', //'Rows per page:',
      ariaLabel: {
        sortDescending: '', //'Sorted descending.',
        sortAscending: '', //'Sorted ascending.',
        sortNone: '', //'Not sorted.',
        activateNone: '', //'Activate to remove sorting.',
        activateDescending: '', //'Activate to sort descending.',
        activateAscending: '' //'Activate to sort ascending.'
      },
      sortBy: '' //'Sort by'
    },
    dataFooter: {
      itemsPerPageText: '', //'Items per page:',
      itemsPerPageAll: '', //'All',
      nextPage: '', //'Next page',
      prevPage: '', //'Previous page',
      firstPage: '', //'First page',
      lastPage: '', //'Last page',
      pageText: '' //'{0}-{1} of {2}'
    },
    datePicker: {
      itemsSelected: '', //'{0} selected',
      nextMonthAriaLabel: '', //'Next month',
      nextYearAriaLabel: '', //'Next year',
      prevMonthAriaLabel: '', //'Previous month',
      prevYearAriaLabel: '' //'Previous year'
    },
    noDataText: '', //'No data available',
    carousel: {
      prev: '', //'Previous visual',
      next: '', //'Next visual',
      ariaLabel: {
        delimiter: '' //'Carousel slide {0} of {1}'
      }
    },
    calendar: {
      moreEvents: '' //'{0} more'
    },
    fileInput: {
      counter: '', //'{0} files',
      counterSize: '' //'{0} files ({1} in total)'
    },
    timePicker: {
      am: '', //'AM',
      pm: '' //'PM'
    },
    pagination: {
      ariaLabel: {
        wrapper: '', //'Pagination Navigation',
        next: '', //'Next page',
        previous: '', //'Previous page',
        page: '', //'Goto Page {0}',
        currentPage: '' //'Current Page, Page {0}'
      }
    }
  },
  customer: {
    titleNamePrefix: {
      mr: 'นาย',
      ms: 'นางสาว',
      mrs: 'นาง'
    }
  }
}
