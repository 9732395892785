import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/settings",
  //   name: "settings",
  //   component: () => import("@/components/Settings.vue"),
  // },
  // {
  //   path: "/",
  //   name: "home",
  //   redirect: "/lead",
  //   // component: () => import("@/components/AgentLayout.vue"),
  // },
  {
    path: "/",
    name: "new-lead",
    component: () => import("@/components/AgentData.vue"),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: "/customer",
    name: "new-lead-customer",
    component: () => import("@/components/CustomerData.vue"),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: "/short",
    name: "new-lead-short",
    component: () => import("@/components/CustomerShort.vue"),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: "/event/:id_event",
    name: "new-lead-event",
    component: () => import("@/components/CustomerEventData.vue"),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: "/event_short/:id_event",
    name: "new-lead-event-short",
    component: () => import("@/components/CustomerEventShort.vue"),
    meta: {
      layout: 'landing'
    }
  },
  {
    path: "*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/pages/error/NotFoundPage.vue"),
    meta: {
      layout: "error",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
